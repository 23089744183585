import React,{ Component, Fragment } from "react";
import moment from 'moment';
import Carousel, { slidesToShowPlugin, arrowsPlugin, Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import ToolTip from 'components/ToolTip';
import Dish from './Dish';
import ProductCarousel from './ProductCarousel';
import PaperButton from 'react-paper-button';
import { intersect,diff } from '../../lib';
import { setModal } from "../../store/modal";
import SideDishModal from '../../routes/MenuModify/Plan/Main/Day/SideDishModal';
import noImage from 'assets/no_img.svg';
class Days extends Component {
    constructor(props) {
        super(props);
        const { checkoutMealPlan,checkoutPlanParams } =this.props;
        const { mealTypes } = checkoutPlanParams;
        const activeMealType = mealTypes?mealTypes[0]:null;
        this.state={
            activeStamp:(checkoutMealPlan)?Object.keys(checkoutMealPlan)[0]:'',
            isEdit:false,
            activeMealType,
            mealPlanUpdate:{},
            width:window.innerWidth,
            sliderValue:0
        }
    }
    updateWidth = () => {
      this.setState({ width: window.innerWidth });
    };
    componentDidMount() {
      window.addEventListener('resize', this.updateWidth);
    }
  
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWidth);
    }
    renderSidesProduct = () =>{
      const { activeMealType, activeStamp,mealPlanUpdate } = this.state;
      const { customPlanSides, checkoutMealPlan, dataArray } = this.props;
      const { typeList,productsList } = dataArray;
      // let unix = date.unix(); // so in the products card we will have the time stamp
      const { children:mealTypeChildren } = typeList[activeMealType];
      // const dayNum = date.date() - 1;
      const dayNum = moment.unix(activeStamp).date() - 1; // TODO: check logic
      const sideDish = (mealTypeChildren.id!=undefined)?(mealPlanUpdate!=undefined && mealPlanUpdate[activeStamp]!=undefined && mealPlanUpdate[activeStamp][mealTypeChildren.id]!=undefined)?mealPlanUpdate[activeStamp][mealTypeChildren.id]:checkoutMealPlan[activeStamp][mealTypeChildren.id]:null;
      // console.log('sideDish');
      // console.log(checkoutMealPlan[activeStamp]);
      // console.log(activeMealType);
      // console.log(sideDish);
      // console.log(mealTypeChildren);
       const days= productsList && (sideDish!=null) && Object.keys(sideDish).map((sideId, idx) => {
              const plan = customPlanSides.find(el => +el.mealType_id === mealTypeChildren.id && +el.side_type_id == sideId) || {};
              let prodKeys1 = (plan.data || {})[dayNum] || [];
              const dishId=sideDish[sideId];
              const { title, image, calories, protein,carb,fat } = productsList[dishId] || {};
              const imgUrl = image ? `${image}` : noImage;
            // return (
            //   <div className='sidedish' key={idx}>
            //    <div className='sidedish-content'>
            //    <div className='image' ><img src={imgUrl} /></div>
            //    <div class="image-caption-holder">
            //         <p className='title'>{title}</p>
            //         {title != 'No side' && <p>
            //         <span>{`Kcal ${(calories/1000)}`}</span>
            //         <span>
            //             {`PRO ${protein/1000}g`}
            //           </span>
            //         <span>
            //             {`CHO ${carb/1000}g`}
            //           </span>
            //           <span>
            //             {`F ${fat/1000}g`}
            //           </span>
            //         </p>}
            //         <p className="link" onClick={() =>this.showSideModal(activeStamp,prodKeys1, dishId, sideId,mealTypeChildren.id)}>Change</p>
            //     </div>
            //    </div>
            //  </div>
            // );
            return <div className="product transition">
                            <div className="img-holder d-card-img">
                                <div className="image_click ">
                                    <img src={imgUrl} />
                                    <h3 className="">Sides {sideId}</h3>
                                </div>
                            </div>
                            <div className='product-info'>
                                <div className="inner d-card-caption ">
                                    <h2 className="product-title transition">{title}</h2>
                                </div>
                                <div className="d-flex discrd-ftr">
                                {title != 'No side' && <div className='ing-info'>
                                        <div className="main-dish">
                                            <div className="mb-2">
                                                <span className="card-kacal"><i><img src="" /></i>Kcal {calories/1000}</span><span>PRO {protein/1000}g,</span><span>CHO {carb/1000}g,</span><span>F {fat/1000}g</span>
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="discrd-ftr-lt">
                                        <button className="btn-label-turq-outline d-v-more-btn" onClick={() =>this.showSideModal(activeStamp,prodKeys1, dishId, sideId,mealTypeChildren.id)}>Change</button>
                                    </div>
                                </div>
                            </div>
                    </div>;
          });
      var breakpoints={
            1400: {
              slidesPerPage: 1,
              slidesPerScroll:1
            }
        };
          return  <ProductCarousel slides={days} showArrow={false} slidesPerPage={1} breakpoints={breakpoints} />;
    }
    showSideModal = (currentDate, prodKeys, dishId, sideId, currentType) => {
      const {setModal,dataArray,clearModal, user } = this.props;
      const { productsList:products }=dataArray;
      const { dishdislikes,eliminate_groups } = user;  
      var sides={};
      var selected={};
      const sidelist =prodKeys.filter(function (dishId1) {
        const { group,spicy } = products[dishId1] || {};
        let isEliminate = true;
        if(eliminate_groups.length>0){ 
          // isEliminate = !(eliminate_groups.indexOf(group)>-1); 
          isEliminate = !(intersect(group,eliminate_groups).length); 
          if(isEliminate){
            isEliminate=!(eliminate_groups.indexOf(14)>-1 && spicy==1); // Spicy Group and dish set as spicy
         }
        }
        if(dishdislikes.length>0 && isEliminate){
          isEliminate = !(dishdislikes.indexOf(+dishId1)>-1); 
        }
        return isEliminate;
    }).map((dishId1, idx) => {
        const { title, fat, protein, carb, calories } = products[dishId1] || {};
          let rObj = {}
          rObj[dishId1] = title;
          sides[dishId1] = title + ' (fat: '+fat/1000+', protein '+protein/1000+', carbs '+carb/1000+', calories '+calories/1000+')';
          if(dishId==dishId1){
            selected={value:dishId1,label:sides[dishId1]};
  
          }
        return rObj;
      });
  
      setModal({ headLine: 'Change your sides', message:
        (
          <div>
              <SideDishModal sidelist={sides} currentType={currentType} currentDate={currentDate} sideId={sideId} dishId={selected} changeMealside={(currentDate, currentType,value,sideId)=>{this.onPressUpdateMeal(currentDate, currentType,value,false,sideId);}} />
              <p align="center">
              <PaperButton onClick={() => clearModal()} className='button-small transition next'>
                      <span>Done</span>
              </PaperButton></p>
          </div>
        )
      });
    }
    changePlan = (currentDate, currentType, dishId,sideId='') => {
      const { checkoutMealPlan:mealPlan, setCheckoutMealPlan } = this.props;
      setCheckoutMealPlan({ ...mealPlan, [currentDate]: { ...mealPlan[currentDate], [currentType]:{  ...mealPlan[currentDate][currentType], [sideId]: + dishId } } });
    };
    renderCarousel =(breakpoints,slidesPerPage=4,slides,showArrow=true)=>{

      const showArrows = slides.length > slidesPerPage && showArrow;
          return <Carousel
          arrowLeft={showArrows?<span className="car-arrow"><i className="fa fa-chevron-left"></i></span>:null}
          arrowRight={showArrows?<span className="car-arrow"><i className="fa fa-chevron-right"></i></span>:null}
          addArrowClickHandler={showArrows}
        //   onChange={this.onSliderChange}
          slidesPerPage={slidesPerPage}
          slidesPerScroll={slidesPerPage}
          slides={slides}
          breakpoints={breakpoints}
          draggable={true}
          plugins={[
            'clickToChange',
            'centered',
            {
              resolve: slidesToShowPlugin,
              options: {
               numberOfSlides: 2
              }
            },
        ]}
          />;
          
    }
    onSliderChange(value){
      this.setState({sliderValue:value});
    }
    renderProductCarousel =(breakpoints,slidesPerPage=4,slides,showArrow=true,draggable=false)=>{
      const showArrows = slides && showArrow && slides.length > slidesPerPage;
      return <Carousel
      arrowLeft={showArrows?<span className="car-arrow"><i className="fa fa-chevron-left"></i></span>:null}
      arrowRight={showArrows?<span className="car-arrow"><i className="fa fa-chevron-right"></i></span>:null}
      addArrowClickHandler={showArrows}
      onChange={this.onSliderChange}
      slidesPerPage={slidesPerPage}
      slidesPerScroll={slidesPerPage}
      slides={slides}
      draggable={draggable}
      breakpoints={breakpoints}
      plugins={[
        {
          resolve: arrowsPlugin,
          options: {
            arrowLeft: <span className="car-arrow"><i className="fa fa-chevron-left"></i></span>,
            arrowLeftDisabled:<span className="car-arrow"><i className="fa fa-chevron-left"></i></span>,
            arrowRight: <span className="car-arrow"><i className="fa fa-chevron-right"></i></span>,
            arrowRightDisabled: <span className="car-arrow"><i className="fa fa-chevron-right"></i></span>,
            addArrowClickHandler: true,
          }
        }
      ]}
      />;
      
}
    renderDates = ()=>{
      const { width } = this.state;
        const { checkoutMealPlan } = this.props;
        const { activeStamp,isEdit } = this.state;
        var breakpoints={
            1400: {
              slidesPerPage: 7,
              slidesPerScroll:1
            },
            992: { // these props will be applied when screen width is less than 1000px
              slidesPerPage: 7,
              slidesPerScroll:1,
            },
            479: {
              slidesPerPage: 5,
              slidesPerScroll:1,
            },
        };
        const today = moment().startOf('day');
        const dates = Object.keys(checkoutMealPlan).map((day,index)=>{
            const date = moment.unix(day); 
            const buffered =(today.unix()>day) || date.isBetween(today, today.clone().add(3, 'day'), '[)');
            return (
                <div
                key={day}
                className={`slide-date-con ${(activeStamp==day) ? 'active-date' : ''}`}
                onClick={() =>{ 
                if(isEdit&&buffered){
                }else{
                  this.setState({activeStamp:day});
                }
            }}
            >
                <div className="slide-date">
                <p className="slide-date-top">{moment.unix(day).format('ddd')}</p>
                <p className="slide-date-bt">
                    <span>{moment.unix(day).format('DD/MM')}</span>
                </p>
                </div>
                
            </div>
            );
        });
        return <div className="slide-dates">{width>=768 && this.renderCarousel(breakpoints,7,dates,false)}{width< 768 && dates}</div>;
    }
    confirmSkipModal = (date) => {
      const {setModal,skipTheDay,clearModal,checkoutPlanParams} = this.props;
      const newDate = moment.unix(checkoutPlanParams['end_date']).utc().add(1,'days').startOf('day').unix();
       setModal({ headLine: 'Are you sure?', message:
         (
           <div>
             <ul className="list">
               <li>This action cannot be reversed.</li>
               <li>A day will be added to the end of your plan.</li>
               <li>Default dishes will be automatically selected unless you change the dishes according to your preferences.</li>
               <li>Please inform us by message in case you want us to check the menu</li>
             </ul>
               <p align="center">
                     <PaperButton onClick={() =>{skipTheDay(date,newDate);clearModal();}} className='button-small transition next'>
                       <span>Yes</span>
                     </PaperButton>
                     </p>
              <p align="center">
                     <PaperButton onClick={() =>clearModal()} className='button-small  transition next'>
                       <span>No</span>
                     </PaperButton>
                     </p>
           </div>
         )
       });
     }
    renderDays = () =>{
      const { width } = this.state;
     
      var breakpoints={
          1400: {
            slidesPerPage: 3,
            slidesPerScroll:3
          },
          810: { // these props will be applied when screen width is less than 1000px
            slidesPerPage: 2,
            slidesPerScroll:2,
          },
          479: {
            slidesPerPage: 1,
            slidesPerScroll:1,
          },
      };
        const { activeStamp } = this.state;
        const { checkoutMealPlan, dataArray,setModal } = this.props;
        const { typeList, productsList } = dataArray;
        const products= checkoutMealPlan[activeStamp] || {};
        console.log('products');
        console.log(products);
        let tcalories=0,tfat=0,tprotein=0,tcarb=0;
        const days=productsList && Object.keys(products).map((type_id)=>{
           if(typeof products[type_id] !== 'object'){
            const { children } = typeList[+type_id];
            const dish = productsList[products[type_id]] || {};
            tcalories+=(dish.calories/1000);
            tprotein+=(dish.protein/1000);
            tfat+=(dish.fat/1000);
            tcarb+=(dish.carb/1000);
            const sideDish =[];
            if(children){
              products[children.id]!=undefined && Object.keys(products[children.id]).map((dishId,)=>{
                const childdish = productsList[products[children.id][dishId]] || {};
                sideDish.push(childdish);
                if(childdish.title!=undefined){
                  tcalories+=(childdish.calories/1000);
                  tprotein+=(childdish.protein/1000);
                  tfat+=(childdish.fat/1000);
                  tcarb+=(childdish.carb/1000);
                }
              }); 
            }
              return <Dish dataArray={dataArray} setModal={setModal} product={dish} typeList={typeList} type_id={type_id} sideDish={sideDish} />;
           }
        });
        const today = moment().startOf('day');
        const date = moment.unix(activeStamp); 
        const buffered =(today.unix()>activeStamp) || date.isBetween(today, today.clone().add(3, 'day'), '[)');
        return <div className="day-info">
          <div className="day-info-top">
            <div className="day-info-details">
              <h5>{moment.unix(activeStamp).format('dddd, Do MMMM,YYYY')}</h5>
              <p><span className="total-kcal">Total Calories: {tcalories},</span> Fat: {tfat}g, Protein: {tprotein}g, CHO: {tcarb}g</p>
            </div>
           {!buffered && <div className="day-info-action">
            <PaperButton onClick={() => { this.setState({isEdit:true})}} className='button-small transition back-btn'>
                  <span>Change Items</span>
            </PaperButton>
            <div className='control-buttons'>
              <div className="button">
                <span
                  onClick={() => this.confirmSkipModal(activeStamp)} //skipTheDay(unix)}//changeSkipped(unix)}
                >
                  Skip delivery
                </span>
                <ToolTip
                  overlayClassName={'on-skip'}
                  tooltipDirection={'bottomRight'}
                  trigger={['click']}
                  tooltipText={<div>
                      <Fragment>
                        <div className='regular-bold'>Click to skip this delivery.</div>
                        <div className='t-total'><span className='green'>New delivery will appear at the end of the plan.</span></div>
                        <div className='regular-bold'>Meals for this day will be selected based on the recommendations of a nutritionist.</div>
                        <div className='t-total'><span className='green'>You can change the selection and select the meals you need.</span></div>
                      </Fragment>
                  </div>}
                >
                  <span className='skips'>?</span>
                </ToolTip>
              </div>
            </div>
           </div>}
          </div>
          <div className="product-list-container">
            {/* {width>=768 && this.renderProductCarousel(breakpoints,3,days)} */}
            {width>0 && <Fragment>{days}</Fragment>}
          </div>
          {buffered && <p className="buffer-text">You cannot edit or skip this day. Items are delivered or in the process of creation.</p>}
        </div>;
    }
    renderMealTypes = () =>{
      const {  dataArray, checkoutPlanParams } = this.props;
      const { typeList } = dataArray;
      const { activeMealType } = this.state;
      const { mealTypes } = checkoutPlanParams;
     
      console.log(mealTypes);
      const list= mealTypes && mealTypes.map((item,index)=>{
        const type = typeList && typeList[item];
        if(type.parent_id==0){
                return <div className={`diet-list ${activeMealType==item ? 'diet-active' : ''}`} onClick={() => this.setState({activeMealType:item})}>
                    <span>{type.title}</span>
                </div>
        }
        });
        return <div className="checkout-diet-list">
            {list}
            {/* {this.renderCarousel(breakpoints,10,list)} */}
        </div>;
    }
    onPressUpdateMeal = (currentDate, currentType, dishId, isConfirmDislike=false, sideId=null) =>{
      const { dataArray,user,checkoutMealPlan:mealPlan, setModal,clearModal } = this.props;
      const { allergies, dislikes } = user;
      const { productsList,typeList, ingredientList } = dataArray;
      const { ingredients } = productsList[dishId] || {};
      const { mealPlanUpdate } = this.state;
      let is_allergies=false;
      
     if(ingredients!=undefined&&ingredients.length>0){
       if (allergies.length>0 && !isConfirmDislike) {
         is_allergies = intersect(ingredients,allergies).length;
         if(is_allergies){
          let ingList =(ingredientList!=undefined)?allergies.map((val)=>{
            return ingredientList[val];
          }):[];
           setModal({ headLine: 'Ingredient as a allergy', message:
              (
                <div>
                  <ul className="list">
                  <li><strong>Allergy :</strong> {ingList.join(',')}</li>
                    <li>Sorry, due to your allergy preference this dish is not available for selection.</li>
                  </ul>
                    <p align="center">
                          <PaperButton onClick={() =>clearModal()} className='button-small  transition next'>
                            <span>Ok</span>
                          </PaperButton>
                          </p>
                </div>
              )
            });
               return false;
         }
       }
       // console.log('dislikes');
       // console.log(dislikes);
       if(!is_allergies && dislikes.length>0 && !isConfirmDislike){
         // console.log('ingredients');
         // console.log(ingredients);
         is_allergies = intersect(ingredients,dislikes).length;  
           if(is_allergies){
            let ingList =(ingredientList!=undefined)?dislikes.map((val)=>{
              return ingredientList[val];
            }):[];
             setModal({ headLine: 'Ingredient as a Dislike', message:
              (
                <div>
                  <ul className="list">
                  <li><strong>Dislike :</strong> {ingList.join(',')}</li>
                    <li>This dish has an ingredient which is in your dislike list. Please note we would not be able to remove the ingredient, if you choose to opt for this dish.If not, we would recommend you to select another dish</li>
                  </ul>
                    <p align="center">
                          <PaperButton onClick={() =>{this.onPressUpdateMeal(currentDate, currentType, dishId, true,sideId);clearModal();}} className='button-small transition next'>
                            <span>Yes</span>
                          </PaperButton>
                          </p>
                    <p align="center">
                          <PaperButton onClick={() =>clearModal()} className='button-small  transition next'>
                            <span>No</span>
                          </PaperButton>
                          </p>
                </div>
              )
              });
               return false;
          } 
       }
     }
     const selectSameDayDish = ()=>{
      const { closeModal } = this.props;
      if(sideId!=null){
        let cdata = (mealPlanUpdate[currentDate]!=undefined)?mealPlanUpdate:{ ...mealPlanUpdate, [currentDate]:mealPlan[currentDate]};
          this.setState({mealPlanUpdate:{ ...cdata, [currentDate]: { ...cdata[currentDate], [currentType]:{  ...cdata[currentDate][currentType], [sideId]: dishId } } }});
      }else{
        let updateData = { ...mealPlanUpdate, [currentDate]: { ...mealPlanUpdate[currentDate], [currentType]: dishId } };
        this.setState({mealPlanUpdate:updateData});
      }
       // setIsMenuUpdated(true);
       // setCheckmealPlanUpdate(updateData);
      //  if(planParams?.id){
      //    updateDayMeals(planParams?.id,updateData,'');
      //  }
       closeModal();
     }
     const isSameDish = this.checkSameDish(mealPlan,mealPlanUpdate,currentDate,currentType,dishId);
     if(isSameDish){
           setModal({ headLine: 'Same Dish Selection', message:
              (
                <div>
                  <ul className="list">
                    <li>Please note you have selected same dish for {typeList[isSameDish].title}. Do you want to select same Dish? </li>
                  </ul>
                    <p align="center">
                          <PaperButton onClick={selectSameDayDish} className='button-small transition next'>
                            <span>Yes</span>
                          </PaperButton>
                          </p>
                    <p align="center">
                          <PaperButton onClick={() =>clearModal()} className='button-small  transition next'>
                            <span>No</span>
                          </PaperButton>
                          </p>
                </div>
              )
              });
       return false;     
     }else{
        if(sideId!=null){
          let cdata = (mealPlanUpdate[currentDate]!=undefined)?mealPlanUpdate:{ ...mealPlanUpdate, [currentDate]:mealPlan[currentDate]};
          this.setState({mealPlanUpdate:{ ...cdata, [currentDate]: { ...cdata[currentDate], [currentType]:{  ...cdata[currentDate][currentType], [sideId]: dishId } } }});
        }else{
          this.setState({mealPlanUpdate:{ ...mealPlanUpdate, [currentDate]: { ...mealPlanUpdate[currentDate], [currentType]: dishId } }});
        }
       // setIsMenuUpdated(true);
       // setCheckmealPlanUpdate(updateData);
      //  if(planParams?.id){
      //    updateDayMeals(planParams?.id,updateData,'');
      //  }
      
     }
   }
   checkSameDish =(mealPlan,mealPlanUpdate,activeDate,mealType,dishId) =>{
         if(mealPlanUpdate[activeDate] != undefined){
           let oldMealKeys = Object.keys(mealPlan[activeDate]);
           let newMealKeys = Object.keys(mealPlanUpdate[activeDate]);
           let checkKeys  = diff(oldMealKeys,newMealKeys);
           let existDish = false;
           if(checkKeys.length>0){
             checkKeys && checkKeys.map((type) => {
               if( dishId == mealPlan[activeDate][type]){
                   existDish=type;
               }
             });
             return existDish;
           }else if(mealPlanUpdate[activeDate][mealType]!=dishId){
             const sameDayDish = Object.values(mealPlanUpdate[activeDate]);
             let index = sameDayDish.indexOf(dishId.toString()) || sameDayDish.indexOf(dishId);
             if(index>-1){
                 const oldkeys = Object.keys(mealPlanUpdate[activeDate]);
                 return oldkeys[index];
               }
           }
          
       }else if(mealPlan[activeDate][mealType]!=dishId){
           const sameDayDish = Object.values(mealPlan[activeDate]);
           // console.log(typeof(dishId));
           if(typeof(dishId)=='string'){
             dishId=parseInt(dishId);
           }
           // console.log(sameDayDish);
           let index = sameDayDish.indexOf(dishId);
           // console.log(index);
           if(index>-1){
             const oldkeys = Object.keys(mealPlan[activeDate]);
             return oldkeys[index];
           }
       }
     return false;
   }
    renderEditDays = () =>{
      const { width } = this.state;
      let breakpoints={
          1400: {
            slidesPerPage: 3,
            slidesPerScroll:3
          },
          810: { // these props will be applied when screen width is less than 1000px
            slidesPerPage: 2,
            slidesPerScroll:2,
          },
          479: {
            slidesPerPage: 1,
            slidesPerScroll:1,
          },
      };
        const { activeStamp,activeMealType, mealPlanUpdate } = this.state;
        const { dataArray,customPlans,checkoutMealPlan,setModal,updateDayMeals,checkoutPlanParams } = this.props;
        const { typeList, productsList } = dataArray;
        const activetypeList = {...(typeList[activeMealType] || null )['children'] || null};
        const plan = customPlans.find(el => +el.mealType_id === +activeMealType) || {};
        const day = moment.unix(activeStamp).date() - 1; // TODO: check logic
        const products = (plan.data || {})[day] || [];
        let tcalories=0,tfat=0,tprotein=0,tcarb=0;
        checkoutMealPlan && checkoutMealPlan[activeStamp] && Object.keys(checkoutMealPlan[activeStamp]).map((type, key) => {
          const dishId = (mealPlanUpdate!=undefined && mealPlanUpdate[activeStamp]!=undefined && mealPlanUpdate[activeStamp][type]!=undefined)?mealPlanUpdate[activeStamp][type]:checkoutMealPlan[activeStamp][type];
          if(typeof dishId !== 'object'){
            const { children } = typeList[+type];
            const dish = productsList?productsList[dishId] || {}:{};
            tcalories+=(dish.calories/1000);
            tprotein+=(dish.protein/1000);
            tfat+=(dish.fat/1000);
            tcarb+=(dish.carb/1000);
            if(children){
              const sidedishId = (mealPlanUpdate!=undefined && mealPlanUpdate[activeStamp]!=undefined && mealPlanUpdate[activeStamp][children.id]!=undefined)?mealPlanUpdate[activeStamp][children.id]:checkoutMealPlan[activeStamp][children.id];
              sidedishId!=undefined && Object.keys(sidedishId).map((sideId)=>{
                  const childdish = productsList[sidedishId[sideId]] || {};
                  if(childdish.title!=undefined){
                    tcalories+=(childdish.calories/1000);
                    tprotein+=(childdish.protein/1000);
                    tfat+=(childdish.fat/1000);
                    tcarb+=(childdish.carb/1000);
                  }
                }); 
              }
          }
        });
        
        const days=productsList && products.map((dishId)=>{
            const theproduct = productsList[dishId] || {};
            const acdishId = (mealPlanUpdate!=undefined && mealPlanUpdate[activeStamp]!=undefined && mealPlanUpdate[activeStamp][activeMealType]!=undefined)?mealPlanUpdate[activeStamp][activeMealType]:checkoutMealPlan[activeStamp][activeMealType];
            return <div className={`day-dish ${(acdishId==dishId) ? 'select-dish' : ''}`} onClick={()=>{this.onPressUpdateMeal(activeStamp,activeMealType,dishId);}}>
              {acdishId==dishId && <span class="selected_dish"><i class="fa fa-check-circle" aria-hidden="true"></i>Selected</span>}
              <Dish dataArray={dataArray} setModal={setModal} product={theproduct} typeList={typeList} type_id={activeMealType} /></div>;
        });
        const today = moment().startOf('day');
        const date = moment.unix(activeStamp); 
        const buffered =(today.unix()>activeStamp) || date.isBetween(today, today.clone().add(3, 'day'), '[)');
        console.log(activetypeList);
        console.log(activetypeList);
        if(Object.keys(activetypeList).length>0){
          breakpoints[1400].slidesPerPage=2;
          breakpoints[1400].slidesPerScroll=2;
        }
        return <div className="day-info edit-day">
          <div className="day-info-top">
            <div className="day-info-details">
              <h5>{moment.unix(activeStamp).format('dddd, Do MMMM,YYYY')}</h5>
              <p><span className="total-kcal">Total Calories: {tcalories},</span> Fat: {tfat}g, Protein: {tprotein}g, CHO: {tcarb}g</p>
            </div>
            <div className="day-info-action"> 
              <PaperButton onClick={() => { updateDayMeals(checkoutPlanParams.id,mealPlanUpdate,''); 
                                this.setState({isEdit:false});}} className='button-small transition animated infinite continue'>
                    <span>Save and exit</span>
              </PaperButton>
            </div>
          </div>
          <div className="mealtypes">
            {this.renderMealTypes()}
          </div>
          <div className="product-list-container">
           {Object.keys(activetypeList).length>0  && (checkoutMealPlan[activeStamp][activetypeList.id]!=undefined) && <Fragment>  <div className=''><div className='row'>
           <div className="Left col-12 col-lg-4 col-md-4 mb-12">
              <div className='sidedishcon'><h3>Side Dishes</h3><div className="side-dish-con">{this.renderSidesProduct()}</div></div>
          </div>
           <div className="Right col-12 col-lg-8 col-md-8 mb-12">
           <h3>Main Dishes</h3>
           {/* {width>=768 && this.renderProductCarousel(breakpoints,2,days)} */}
           {width>0 && days}
          </div></div></div></Fragment>}
            {/* {(Object.keys(activetypeList).length<=0 || (activetypeList && checkoutMealPlan[activeStamp][activetypeList.id]==undefined)) && width>=768 && this.renderProductCarousel(breakpoints,3,days)} */}
            {(Object.keys(activetypeList).length<=0 || (activetypeList && checkoutMealPlan[activeStamp][activetypeList.id]==undefined)) && width>0 && days}
          </div>
        </div>;
    }
    renderPlanInfo =()=>{
      const { checkoutPlanParams, dataArray,checkoutMealPlan } = this.props;
      const { dietList } =dataArray;
      const { diet_id, days_count,exclude_week_day } =checkoutPlanParams;
      console.log(checkoutPlanParams);
      const { activeStamp } = this.state;
      var dayslist={};
      let dateList = Object.keys(checkoutMealPlan);
      dateList.map(function(dateIndex,index){
            dayslist[dateIndex] = 'Jump to date - '+moment.unix(dateIndex).format("dddd, Do MMM");
        });
     const skipdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
     const getExcludeWeek =()=>(exclude_week_day!=undefined && exclude_week_day.length>0)?'No '+exclude_week_day.map(el => skipdays[el]).join(' & '):'Full Week';
    
      return <Fragment>{dietList!=undefined && diet_id!=undefined && <div className="planInfo">
              <h5>{dietList[diet_id]}</h5>
              <p>{days_count+" Days - "+getExcludeWeek()}</p>
              {/* <div className="mealdays-selection"><SelectInput
              labelText='Select My Meals'
              list={dayslist}
              value={{value:activeStamp,label:moment.unix(activeStamp).format("dddd, Do MMM")} || {}}
              name='mealday'
              searchable={false}
              // menuIsOpen={this.state.isShowMenu}
              // onBlur ={ this.handleonBlur}
              // onFocus ={this.handleOnFocus}
              escapeClearsValue={true}
              // onChange={ 
              //     (name,value) => { this.changeForm(moment.unix(value.value),dateList.indexOf(value.value)); this.setState({isShowMenu:false}); }
              //     }
              /></div> */}
      </div>}</Fragment>;
  }
    render(){
      const { isEdit } = this.state;
        return <div>
                <div className="sticky-info">
                {this.renderPlanInfo()}
                {this.renderDates()}
                </div>
                {!isEdit && this.renderDays()}
                {isEdit && this.renderEditDays()}
        </div>;
    }
}
export default Days;